<template>
  <div class="contact-information-content">
    <ul v-if="!(config.contact == '')">
      <li
        v-for="(info, i) in config.contact"
        :key="'contact-entry-' + i"
        :class="'contact-entry-' + i"
      >
        <i :class="info.icon" />
        <inline-edit element="span" :target="info.data" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["config"],
  mounted() {
    if (Array.isArray(this.config.contact)) {
      for (let i = 0; i < this.config.contact.length; i++) {
        if (!this.config.contact[i].data) {
          this.config.contact[i].data = { value: this.config.contact[i].data };
          this.config.contact[i].icon = "none";
        } else if (typeof this.config.contact[i].data == "string") {
          this.config.contact[i].data = { value: this.config.contact[i].data };
        }
      }
    } else {
      this.config.contact = [];
      this.config.contact.push({ data: "temp", icon: "none" });
    }
  },
};
</script>
